<template>
  <div class="my-6">
    <h3 class="font-bold">List of {{ route.name }}</h3>
    <span class="text-sm text-gray-500"
      >Here you can observe all items related to the projects.
    </span>
  </div>
  <div class="flex mb-6 justify-end">
    <router-link to="/projects/create">
      <Button color="primary" :text="`Create new ${(route.name.slice(0, -1)).toLowerCase()}`" />
    </router-link>
  </div>
  <div class="card">
    <DataTable
      :headers="headers"
      :data="data"
      :options="options"
      color="primary"
    />
  </div>
</template>

<script>
import DataTable from "../../components/Elements/Datatable.vue";
import Button from "../../components/Elements/Button.vue";
import { inject, onMounted, ref } from "vue";
import { GET, DELETE } from "../../services/api";
import { useRoute, useRouter } from "vue-router";

export default {
  name: "projects",
  components: {
    DataTable,
    Button,
  },
  setup() {
    const headers = [
      { key: "Id", value: "id" },
      { key: "Name", value: "name" },
      { key: "Sprint", value: "sprint" },
      { key: "Total Hours", value: "required_hours" },
      { key: "Client", value: "client" },
      { key: "Options", value: "" },
    ];

    const route = useRoute();
    const router = useRouter();
    const modal = inject("modal");
    const alert = inject("alert");
    const loader = inject("loading");
    const data = ref([]);

    onMounted(async () => {
      try {
        loader.open();
        data.value = await GET("projects");
        data.value = data.value.map(item =>{
          const entryInHours = item.required_hours/60;
          return {...item, required_hours: entryInHours + "h"}
        })
        //data.value = data.value;
        /*data.value = data.value.map( data => data.plans);
        data.value.map( plan => {
          plan.institutions = plan.institutions.name;
          plan.status = plan.deleted_at ? 'INATIVA' : 'ATIVA';
        });*/
        loader.close();
      } catch (e) {
        loader.close();
        if(e.includes("Token") || e.includes("t_dynamik") || e == "Unauthorized") {
          localStorage.removeItem("t_dynamik");
          localStorage.removeItem("info_dynamik");
          router.push({ path: '/login'});
        }
        alert.open("Error", e, "danger");
      }
    });

    const deleteItem = (project) => {
      loader.open();
      const deleteFunction = async function() {
        try{
          loader.open();
          await DELETE(`projects/${project.id}`);
          data.value = data.value.filter((item) => item.id != project.id);
          loader.close();
          alert.open("Success!", `Project was deleted successfully!`, "success");
        }catch(e){
          loader.close();
          if(e.includes("Token") || e.includes("t_dynamik") || e == "Unauthorized") {
            localStorage.removeItem("t_dynamik");
            localStorage.removeItem("info_dynamik");
            router.push({ path: '/login'});
          }
          alert.open("Error", e, "danger");
        }
      }
      modal.open(
        "Attention",
        "Do you really wish to delete this project?",
        "warning",
        "Yes",
        () => deleteFunction(),
        modal.close()
      );
      loader.close();
    };

    // const show = (r) => {
    //   window.open(`/planos/editar/${r.id}`);
    // };

    const options = [
      { icon: "pen", path: '/projects/edit' , title: "Edit project" },
      { icon: "trash", action: (param) => deleteItem(param), title: "Delete project" },
    ];

    return { headers, data, options, route };
  },
};
</script>

<style>
</style>